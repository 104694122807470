import { createContext, useContext, useState } from "react";
import sessionRoleCheck from "../Utils/jwtSessionAuth";

export const TcContext = createContext();

// session storage data
const tokenSession = localStorage.getItem("token");
const emailSession = localStorage.getItem("emailID");
const referralCodeSession = localStorage.getItem("referralCode");
const referredBySession = localStorage.getItem("referredBy");
const clientIDSession = localStorage.getItem("clientID");
const companyNameSession = localStorage.getItem("companyName");
const interviewerIDSession = localStorage.getItem("interviewerID");
const candidateIDSession = localStorage.getItem("candidateID");

const TcContextProvider = ({ children }) => {
  const sessionTokenDecoded = sessionRoleCheck();

  const [store, setStore] = useState({
    user: {
      userID: sessionTokenDecoded?.userID || null,
      userName: sessionTokenDecoded?.userName || null,
      emailID: emailSession || null,
      token: tokenSession || null,
      referralCode: referralCodeSession || null,
      role: sessionTokenDecoded?.role || null,
      referredBy: referredBySession || null,
      companyName: companyNameSession || null,
      clientID: clientIDSession || null,
      interviewerID: interviewerIDSession || null,
      candidateID: candidateIDSession || null,
    },
    singleCandidate: {},
    jobs: [],
    candidates: [],
    interviewers: {
      userID: null,
      workExperience: null,
      domainExpertise: null,
      emailID: null,
      slots: [],
      costPerInterview: null,
    },
    clients: {},
    slots: [],
    meetings: [],
    clientHireNowEmail: null,
  });

  return (
    <TcContext.Provider value={{ store, setStore }}>
      {children}
    </TcContext.Provider>
  );
};

export default TcContextProvider;

export const useTcContext = () => useContext(TcContext);

import axios from "axios";

let tokenStorage = {
  token: "",
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": localStorage.getItem("token"),
  },
  // timeout: 1000,
});

// register user
const registerUser = async (resultBody) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post("/api/users/register", resultBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// login user
const loginUser = async (resultBody) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post("/api/users/login", resultBody)
      .then((response) => {
        tokenStorage.token = response.data.result.token;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// verify user on email confirmation
const verifyUser = async (code) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/users/confirmation/${code}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// get email on the basis of referral code
const getEmailId = (resultBody) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post("/api/users/getemailid", {
        referralCode: resultBody,
      })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// add single candidate into database
const addCandidate = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/api/addSingleCandidate`, resultdata)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// add bulk candidate into database
const addBulkCandidate = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/api/addbulkcandidates`, resultdata)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// show candidate on client data-grid
const showCandidatesOnClientByCompany = async (companyName) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/candidates?companyName=${companyName}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// show single client candidate filter by id
const showSingleClientCandidate = async (candidateID, isJD) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/candidate/${candidateID}?isJD=${isJD}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// show access user on client data-grid
const showClientData = (companyName) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/client?companyName=${companyName}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      })

      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// add access user into database
const addClientAccessUser = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/api/addclients`, resultdata)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// get all job details from database
const getAllJobDetails = async (companyName) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/jobsByCompanyName?companyName=${companyName}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// add job into database
const addJob = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/api/addjob`, resultdata)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// update a job in database
const updateJob = async (jobData) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .put(`/api/updateJob/${jobData?.jobID}`, jobData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// get jobDescription by jobID
const getJobDescription = async (jobID) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/getJDByID/${jobID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// add interviewer slots
const interviewerSchedule = async (interviewerID, newSchedule) => {
  console.log("interviewer data", newSchedule);
  const promise = new Promise((resolve, reject) => {
    instance
      .patch(`/api/interviewers/${interviewerID}/updateSchedule`, {
        newSlots: newSchedule,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// get all interviewers
const getAllInterviewers = async () => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/interviewers`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// get single interviewer data filter by ID
const getSingleInterviewerByID = async (interviewerID) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/interviewers/${interviewerID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// schedule interview from client side
const scheduleInterview = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/api/meeting/scheduleInterview`, resultdata)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

const sendMailIcs = async (sendMailMeetingResponse) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post("/api/sendMailIcs", sendMailMeetingResponse)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

// interview evaluation from client side
const interviewEvaluation = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/save-evaluation`, resultdata)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};
// get all jobs
const getAllJobs = async () => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/allJobs`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};
// get jobdetails by ID
const getJobByID = async (jobID) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/jobByID/${jobID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// get clientInfo details
const getClientInfo = async (companyName) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/clientInfo?companyName=${companyName}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// update profile of client data
const putClientProfile = async (clientData) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .put(`/api/client/updateClient`, clientData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// update profile of candidate data
const putCandidateProfile = async (candidateData) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .put(
        `/api/candidate/updateCandidate/${candidateData?.candidateID}`,
        candidateData
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

// instant apply for jobs
const candidateJobApply = async (resultdata) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .post(`/api/candidateJobApply`, resultdata)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

// meetingByClientID

const meetingByClientID = async (clientID) => {
  const promise = new Promise((resolve, reject) => {
    instance
      .get(`/api/meetingByClientID?clientID=${clientID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

const getJobDataFromSheet = async () => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(
        "https://script.google.com/macros/s/AKfycby7xt338FQOOtOYQamfIszLHCqJFWHLC83_QWKA8E5vl6G_sjpZva34DvbWKsKvpe8XwA/exec"
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

const saveEmailNewsletter = async (emailID) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(
        "https://script.google.com/macros/s/AKfycbyitBX7tEzn1pcwNsQKbi8iUiEUgveBICmGyUXLFvu6Kk2vdOzIhIjHnQ2SIMJXxRcIKA/exec",
        emailID
      )
      .then((response) => {
        if (response.data.result === "success") {
          instance
            .get(`/api/users/sendEmail/${response.data.newRow[0]}`)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((err) => reject(err));
        }
      })
      .catch((error) => reject(error));
  });

  return promise;
};

export {
  registerUser,
  loginUser,
  getEmailId,
  verifyUser,
  addCandidate,
  addBulkCandidate,
  showCandidatesOnClientByCompany,
  showClientData,
  addClientAccessUser,
  addJob,
  updateJob,
  getAllJobDetails,
  showSingleClientCandidate,
  interviewerSchedule,
  getAllInterviewers,
  getSingleInterviewerByID,
  scheduleInterview,
  interviewEvaluation,
  getJobDescription,
  getAllJobs,
  getJobByID,
  getClientInfo,
  putClientProfile,
  putCandidateProfile,
  candidateJobApply,
  meetingByClientID,
  sendMailIcs,
  getJobDataFromSheet,
  saveEmailNewsletter,
};

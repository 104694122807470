import JWTDecode from "jwt-decode";

const sessionRoleCheck = () => {
  const sessionTokenWithBearer = localStorage.getItem("token");

  if (sessionTokenWithBearer) {
    const sessionToken = sessionTokenWithBearer.split(" ")[1];

    const sessionTokenDecoded = JWTDecode(sessionToken);

    console.log(sessionTokenDecoded);

    return sessionTokenDecoded;
  } else {
    return null;
  }
};

export default sessionRoleCheck;

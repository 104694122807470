import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { useTcContext } from "./Context/TcContext";

// css
import "./styles/Globals.css";
import "./styles/App.css";

// Loader import
import Loader from "./Components/Loader";

// Binder Components
import {
  Footer,
  PublicNavbar,
  CandidateNavbar,
  CandidateFooter,
} from "./Components/Binder/binder.index";

//  Auth components
import {
  ClientRegister,
  Login,
  UserVerification,
  Thankyou,
  CandidateRegister,
  CandidateLogin,
  CandidateOnBoard,
  OAuth2Verification,
} from "./Components/Auth/auth.index";

//  Client components
import {
  AccessManagement,
  ClientDashboard,
  ShowCandidates,
  AddJob,
  SingleCandidateProfile,
  InterviewInstruction,
  UpdateClientProfile,
  Jobs,
  UpdateJob,
} from "./Components/Dashboard/Client/client.index";

//  Interviewer components
import {
  InterviewsDashboard,
  InterviewScheduling,
  InterviewEvaluation,
} from "./Components/Dashboard/Interviewer/interviewer.index";

// Candidate components
import {
  CandidateDashboard,
  UpdateCandidateProfile,
  CandidateFeaturedJobs,
} from "./Components/Dashboard/Candidates/candidate.index";

// Jobs components
import {
  FeaturedJobs,
  CompanyProfile,
  JobDetails,
  JobListing,
} from "./Components/Jobs/jobs.index";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const ClientDataInitialiser = lazy(() =>
//   import("./Components/Dashboard/ClientDataInitialiser")
// );

import ClientDataInitialiser from "./Components/Dashboard/ClientDataInitialiser";
import CandidateDataInitialiser from "./Components/Dashboard/CandidateDataInitialiser";

// calendly component
const Calendly = lazy(() => import("./Components/Calendly/Calendly"));
const Referral = lazy(() => import("./Components/Dashboard/Referral"));
// main file imports
const SampleCandidate = lazy(() => import("./Components/SampleCandidate"));
const PageNotFound = lazy(() => import("./Components/PageNotFound"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));

// Landing pages
const CandidateLandingPage = lazy(() =>
  import("./Components/CandidateLandingPage")
);
const ClientLandingPage = lazy(() => import("./Components/ClientLandingPage"));

// dashboard component
const MainDashboard = lazy(() =>
  import("./Components/Dashboard/MainDashboard")
);

function App() {
  const { store, setStore } = useTcContext();

  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <ClientLandingPage />
                  <Footer />
                </Suspense>
              </>
            }
          />{" "}
          <Route
            path="/request-demo"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <Calendly />
                  <Footer />
                </Suspense>
              </>
            }
          />
          <Route
            path="/candidate/main"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <CandidateNavbar />
                  <CandidateLandingPage />
                  <CandidateFooter />
                </Suspense>
              </>
            }
          />
          <Route
            path="/jobs-listing"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <JobListing />
                  <Footer />
                </Suspense>
              </>
            }
          />
          <Route
            path="verify"
            element={
              <Suspense fallback={<Loader />}>
                <OAuth2Verification />
              </Suspense>
            }
          />
          <Route
            path="/jobs"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <Outlet />
                  <Footer />
                </Suspense>
              </>
            }
          >
            <Route
              index
              element={
                <>
                  <PublicNavbar />
                  <FeaturedJobs />
                </>
              }
            />
            <Route
              path=":companyName/job/:jobID"
              element={
                <>
                  <JobDetails />
                </>
              }
            />
            <Route
              path=":companyName"
              element={
                <>
                  <CompanyProfile />
                </>
              }
            />
          </Route>
          <Route
            path="/privacy-policy"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <PrivacyPolicy />
                  <Footer />
                </Suspense>
              </>
            }
          />
          <Route path="sample-candidate/1" element={<SampleCandidate />} />
          {/* to test component */}
          <Route
            path="/interview-landing/:candidateID"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <InterviewInstruction />
                  <Footer />
                </Suspense>
              </>
            }
          />
          {/* Client routes starts */}
          {store && store?.user?.token && store?.user?.role === "Client" && (
            <Route
              path="/dashboard"
              element={
                <>
                  <ClientDataInitialiser />
                  <MainDashboard>
                    <Suspense fallback={<Loader />}>
                      <Outlet />
                    </Suspense>
                  </MainDashboard>
                </>
              }
            >
              <Route index element={<ClientDashboard />} />
              <Route
                exact={true}
                path="candidates"
                element={<ShowCandidates />}
              />
              <Route
                path="candidates/:candidateID/:jobID"
                element={<SingleCandidateProfile />}
              />
              <Route path="jobs" element={<Outlet />}>
                <Route index element={<Jobs />} />
                <Route path="add" element={<AddJob />} />
                <Route path="edit/:jobID" element={<UpdateJob />} />
              </Route>
              <Route path="access-management" element={<AccessManagement />} />
              <Route path="profile" element={<UpdateClientProfile />} />
            </Route>
          )}
          {/* Client routes end */}
          <Route
            path="meeting/:meetingID/interview/:interviewerID"
            element={<InterviewEvaluation />}
          />
          <Route
            path="/register"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <ClientRegister />
                  <Footer />
                </Suspense>
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <Login />
                  <Footer />
                </Suspense>
              </>
            }
          />
          <Route
            path="/thank-you"
            element={
              <>
                <Suspense fallback={<Loader />}>
                  <PublicNavbar />
                  <Thankyou />
                </Suspense>
              </>
            }
          />
          <Route
            path="/direct-login/:verificationCode"
            element={
              <Suspense fallback={<Loader />}>
                <UserVerification />
              </Suspense>
            }
          />
          {store &&
            store?.user?.token &&
            store?.user?.role === "Interviewer" && (
              <>
                <Route
                  path="/interviewer"
                  element={
                    <MainDashboard>
                      <Suspense fallback={<Loader />}>
                        <Outlet />
                      </Suspense>
                    </MainDashboard>
                  }
                >
                  <Route path="dashboard" element={<InterviewsDashboard />} />
                  <Route path="referral" element={<Referral />} />
                  <Route
                    path="schedule/:interviewerID"
                    element={<InterviewScheduling />}
                  />
                </Route>
              </>
            )}
          <Route
            path="candidate"
            element={
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              path="register"
              element={
                <>
                  <PublicNavbar />
                  <CandidateRegister />
                  <Footer />
                </>
              }
            />
            <Route
              path="login"
              element={
                <>
                  <PublicNavbar />
                  <CandidateLogin />
                  <Footer />
                </>
              }
            />
            <Route path="onboard" element={<CandidateOnBoard />} />
            {store &&
              store?.user?.token &&
              store?.user?.role === "Candidate" && (
                <Route
                  path="dashboard"
                  element={
                    <>
                      <CandidateDataInitialiser />
                      <MainDashboard>
                        <Suspense fallback={<Loader />}>
                          <Outlet />
                        </Suspense>
                      </MainDashboard>
                    </>
                  }
                >
                  <Route index element={<CandidateDashboard />} />
                  <Route
                    path="candidates/:candidateID"
                    element={<SingleCandidateProfile />}
                  />{" "}
                  <Route
                    path="availableJobs"
                    element={<CandidateFeaturedJobs />}
                  />
                  <Route path="profile" element={<UpdateCandidateProfile />} />{" "}
                  <Route path="referral" element={<Referral />} />
                </Route>
              )}
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useEffect } from "react";
import { useTcContext } from "../../Context/TcContext";
import {
  showCandidatesOnClientByCompany,
  showClientData,
  getAllJobDetails,
  meetingByClientID,
} from "../../Utils/axios";

const ClientDataInitialiser = () => {
  const { store, setStore } = useTcContext();

  useEffect(() => {
    getAllJobDetails(store.user?.companyName)
      .then((res) => {
        setStore((prev) => {
          return {
            ...prev,
            jobs: res,
          };
        });
      })
      .catch((err) => console.log(err));

    showClientData(store.user?.companyName)
      .then((resp) => {
        setStore((prev) => {
          return {
            ...prev,
            clients: resp.clients,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });

    showCandidatesOnClientByCompany(store.user?.companyName)
      .then((data) => {
        setStore((prev) => {
          return {
            ...prev,
            candidates: data.result,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });

    meetingByClientID(store?.user?.clientID)
      .then((res) => {
        setStore((prev) => {
          return {
            ...prev,
            meetings: res.result,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return <></>;
};

export default ClientDataInitialiser;

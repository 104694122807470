import { lazy } from "react";

// Common Login Component ---------
export const Login = lazy(() => import("./Login"));

// ---- Client Auth Components -----
export const ClientRegister = lazy(() => import("./ClientRegister"));

// ---- Candidate Auth Components -----
export const CandidateRegister = lazy(() => import("./CandidateRegister"));
export const CandidateLogin = lazy(() => import("./NewCandidateLogin"));
export const CandidateOnBoard = lazy(() => import("./CandidateOnBoard"));

// ---- MiddleWare Components -----
export const UserVerification = lazy(() => import("./UserVerification"));
export const Thankyou = lazy(() => import("./Thankyou"));
export const ThankyouCalendly = lazy(() => import("./ThankyouCalendly"));

// ---- Google & Linkedin Auth Component -----
export const OAuth2Verification = lazy(() => import("./OAuth2Verification"));

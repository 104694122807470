import { lazy } from "react";

export const ClientDashboard = lazy(() => import("./ClientDashboard"));
export const ShowCandidates = lazy(() => import("./ShowCandidates"));
export const AccessManagement = lazy(() => import("./AccessManagement"));
export const SingleCandidateProfile = lazy(() =>
  import("./SingleCandidateProfile")
);
export const Jobs = lazy(() => import("./Jobs"));
export const AddJob = lazy(() => import("./AddJob"));
export const UpdateJob = lazy(() => import("./UpdateJob"));
export const InterviewInstruction = lazy(() =>
  import("./InterviewInstruction")
);
export const UpdateClientProfile = lazy(() => import("./UpdateClientProfile"));

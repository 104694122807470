import React, { useEffect } from "react";
import { useTcContext } from "../../Context/TcContext";
import { showSingleClientCandidate } from "../../Utils/axios";

const CandidateDataInitialiser = () => {
  const { store, setStore } = useTcContext();

  useEffect(() => {
    showSingleClientCandidate(store?.user?.candidateID, true)
      .then((candidateDataResponse) => {
        console.log(candidateDataResponse);

        setStore((prev) => {
          return {
            ...prev,
            singleCandidate: candidateDataResponse?.result,
          };
        });
      })
      .catch((candidateDataError) => {
        console.log(candidateDataError);
      });

    // meetingByClientID(store?.user?.clientID)
    //   .then((res) => {
    //     setStore((prev) => {
    //       return {
    //         ...prev,
    //         meetings: res.result,
    //       };
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [setStore, store?.user?.candidateID]);
  return <></>;
};

export default CandidateDataInitialiser;

import { lazy } from "react";

// export { default as Referral } from "./Referral";
// export { default as InterviewsDashboard } from "./InterviewsDashboard";
// export { default as InterviewScheduling } from "./InterviewScheduling";
// export { default as InterviewEvaluation } from "./InterviewEvaluation";

export const InterviewsDashboard = lazy(() => import("./InterviewsDashboard"));
export const InterviewScheduling = lazy(() => import("./InterviewScheduling"));
export const InterviewEvaluation = lazy(() => import("./InterviewEvaluation"));
